<template>
  <div v-editable="blok" class="constrain flex py-6 md:py-10 xl:py-24 lg:gap-x-20 bg-light-green lg:bg-transparent relative z-20">

    <!-- Text Section -->
    <div class="w-full lg:w-6/12 flex flex-col justify-center items-center lg:items-start relative z-10">
      <div class="h-full text-center lg:text-left max-w-md md:mx-auto lg:max-w-full lg:mx-0">
        <h1 class="heading-1">{{ blok.title }}</h1>
        <p class="text-neutrals-900 mt-2">{{ blok.subtitle }}</p>

        <!-- Centering the BookingPostcode component below lg -->
        <div class="w-full flex justify-center lg:justify-start md:mt-5">
          <BookingPostcode class="w-full max-w-md" />
        </div>
      </div>

      <!-- Trustpilot Section -->
      <div class="bg-light-green w-full rounded-lg mt-2 xl:mt-20 relative">
        <div class="py-10 px-base">
          <NuxtLink
            to="https://uk.trustpilot.com/review/justhire.com"
            target="_blank"
            rel="noopener noreferrer"
            loading="lazy"
          >
            <p class="font-primary text-black font-bold text-2xl">Excellent</p>
            <NuxtImg
              provider="storyblok"
              class="w-36 absolute right-4 top-4"
              :src="TRUSTPILOT_LOGO_URL"
              alt="Trustpilot Logo"
              loading="lazy"
              format="webp"
            />
            <NuxtImg
              provider="storyblok"
              class="w-44 my-4"
              :src="TRUSTPILOT_STARS_URL"
              alt="Trustpilot Stars"
              loading="lazy"
              format="webp"
            />
            Based on <span class="font-semibold underline">250+ reviews</span>
          </NuxtLink>
        </div>
      </div>
    </div>

    <!-- Image Section -->
    <div class="hidden lg:block lg:w-6/12 flex relative z-10">
      <NuxtImg
        provider="storyblok"
        class="w-full h-full rounded-3xl object-cover"
        :src="blok.image.filename"
        :alt="blok.image.alt"
        width="600px"
        loading="lazy"
        format="webp"
      />
    </div>
  </div>

  <Gradients :gradients="blok.gradients" />
</template>
<script setup lang="ts">
defineProps({ blok: Object });

const TRUSTPILOT_LOGO_URL = "f/237253/320x132/6d8f347146/trustpilot-logo.png";
const TRUSTPILOT_STARS_URL = "/f/237253/512x96/e1a421405b/trust-stars.png";
</script>
